import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import referencePlaceholder from "../images/reference-placeholder.png"
import { ItemsList } from "./utils"
import Label from "./label"
import ReferenceDetails from "./referenceDetails"

const ReferenceList = ({ references, theme }) => (
  <CustomItemsList>
    {references.map(reference => (
      <li key={reference.id}>
        <ReferenceItem reference={reference} theme={theme} />
      </li>
    ))}
  </CustomItemsList>
)

const CustomItemsList = styled(ItemsList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${DesignTokens.spacing[8]};

  & > li {
    width: 100%;
    margin: 0 0 ${DesignTokens.spacing[4]};
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: 769px) {
      margin: 2% 2% 0 0;
      width: calc(98% / 2);
      &:nth-child(-n + 2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1025px) {
      width: calc(96% / 3);
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-right: 2%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

const ReferenceItem = ({ reference, theme }) => (
  <StyledReferenceItem theme={theme} to={`/referenze/` + reference.slug}>
    <img
      src={
        reference.gallery.length
          ? reference.gallery[0].url
          : referencePlaceholder
      }
      alt="Placeholder per le referenze"
    />
    <Label>{reference.project}</Label>
    <h4>{reference.client}</h4>
    <ReferenceDetails reference={reference} />
    <FakeLink>Scopri di più +</FakeLink>
  </StyledReferenceItem>
)

const StyledReferenceItem = styled(Link)`
  color: ${props =>
    props.theme === "dark"
      ? DesignTokens.colors.white
      : DesignTokens.colors.black};
  text-decoration: none;
  display: block;
  img {
    transition: all 0.2s ease-out;
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  h4 {
    transition: all 0.2s ease-in;
    font-weight: 400;
    font-size: ${DesignTokens.fontSizes[6]};
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  ul {
    margin: 0 0 ${DesignTokens.spacing[6]};
    padding: 0;
    list-style-type: none;
    li {
      margin: ${DesignTokens.spacing[1]} 0;
      line-height: 1.2;
    }
  }
  a {
    color: ${DesignTokens.colors.secondary[500]};
    text-decoration: none;
  }
  &:hover {
    img {
      transform: scale(1.025);
    }
    h4 {
      color: ${DesignTokens.colors.secondary[500]};
    }
  }
`

const FakeLink = styled.p`
  color: ${DesignTokens.colors.secondary[500]};
`

export default ReferenceList
